import React from "react"
import { navigate, graphql } from "gatsby"
//import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, FormGroup, Label, Input, Form, Button } from "reactstrap"
import Newsletter from "../components/newsletter"
import BackgroundImage from "gatsby-background-image"

class ContactPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
  
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        'form-name': form.getAttribute("name"),
        ...this.state
      }),
    })
    .then(response => {
      console.log(`${JSON.stringify(response, null, 2)}`)
      navigate(form.getAttribute("action"))
    })
    .catch(error => {
      console.error(`error in submiting the form data:${error}`)
      alert('Sorry, we are not able to submit your message. Please try to contact us directly via email.')
    })
  }
    
  render() {
    const backgroundFluidImageStack = [
      this.props.data.banner.childImageSharp.fluid,
      `linear-gradient(to right, rgba(2, 47, 96, 0.9), rgba(2, 47, 96, 0.5))`
    ].reverse();
    
    return (
      <Layout>
        <SEO title="Contact" />

        <BackgroundImage
          Tag="section"
          className="d-flex justify-content-center align-items-center vh-60"
          fluid={backgroundFluidImageStack}
          backgroundColor={`#022f60`}
        >
          <Container>
            <Row>
              <Col lg="4">
                <h1 className="display-3 text-white">Contact Us.</h1>
                <p className="lead text-white">
                We are happy to help with any questions.
                </p>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <section>
          <Container className="py-3 py-xl-5">
            <Row className="align-items-center">
              <Col xl="4">
                <p className="lead mt-4">
                  Do you have questions? Do not hesitate to contact us for further details.
                </p>
              </Col>

              <Col>
                <Form 
                  action="/contact-success"
                  method="post"
                  data-netlify="true" 
                  name="contact" 
                  netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}>
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <Row form>
                    <Col>
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="email" name="email" id="email" onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input type="text" name="firstName" id="firstName" onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Input type="text" name="lastName" id="lastName" onChange={this.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <Input type="textarea" name="message" id="message" placeholder="Your message ..." onChange={this.handleChange} />
                  </FormGroup>

                  <Button type="submit" color="primary">Send request</Button>
                  <div className="d-inline ml-2 text-muted">or contact us via email at <a href="mailto:jrclive@fh-steyr.at">jrclive@fh-steyr.at</a></div>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="bg-light">
          <Container className="py-2 py-xl-5">
            <Row className="my-5">
              <Col xl="4">
                <h2 className="display-4">Imprint.</h2>
                <p className="lead my-4">
                  §5 E-Commerce Gesetz.
                </p>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col md="6">
                    <address className="lead">
                      FH OÖ F&amp;E GmbH<br />
                      JRC LIVE<br />
                      Roseggerstraße 15<br />
                      4600 Wels
                    </address>
                    
                    <p className="lead">
                      FB Nr. 236733m<br />
                      UID Nr. ATU57300236<br />
                      Firmenbuchgericht: Landesgericht Wels
                    </p>
                  </Col>

                  {/*<Col sm="4" md="3" lg="2">
                    <a href="https://www.logistikum.at">
                      <Img fluid={data.logistikum.childImageSharp.fluid} className="" alt="Logistikum" />
                    </a>
                  </Col>*/}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <Newsletter />
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "contact.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ContactPage